/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, FacebookMessengerShareButton, TelegramShareButton, InstapaperShareButton, RedditShareButton, PinterestShareButton, TumblrShareButton, VKShareButton, OKShareButton, PocketShareButton, LineShareButton, WeiboShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon, FacebookMessengerIcon, TelegramIcon, InstapaperIcon, RedditIcon, PinterestIcon, TumblrIcon, VKIcon, OKIcon, PocketIcon, LineIcon, WeiboIcon } from 'react-share';
function NoeAnimation() {

    const [showNavigate, GetshowNavigate] = useState(false)
    const [showFormulaire, GetshowFormulaire] = useState(false)
    const [mobile, GetMobile] = useState(window.innerWidth < 690)
    const [mobile1, GetMobile1] = useState(window.innerWidth < 585)
    const [mobile2, GetMobile2] = useState(window.innerWidth < 444)
    const [mobile3, GetMobile3] = useState(window.innerWidth < 384)
    const [mobile4, GetMobile4] = useState(window.innerWidth < 320)
    const [mobile5, GetMobile5] = useState(window.innerWidth < 645)
    useEffect(() => {
        const hundleSize = () => {
            GetMobile(window.innerWidth < 690)
            GetMobile1(window.innerWidth < 585)
            GetMobile2(window.innerWidth < 444)
            GetMobile3(window.innerWidth < 384)
            GetMobile4(window.innerWidth < 320)
            GetMobile5(window.innerWidth < 645)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('click', hundleSize)
        }
    }, [])

    const [nom, setNom] = useState("BTR");
    const { encodedString } = useParams();
    const decodedString = decodeURIComponent(encodedString);

    useEffect(() => {
        if (decodedString !== "undefined") {
            setNom(decodedString);
        } else {
            setNom("BTR");
        }
    }, [decodedString]);
    const [actuelNomvisit, setActNomVisit] = useState("BTR");

    // Encodage du nom pour une utilisation dans l'URL
    const encodedNomvisit = encodeURIComponent(actuelNomvisit);

    const shareUrl = `https://festival.burundientempsreel.com/${encodedNomvisit}`;
    const title = `${actuelNomvisit} tient à vous souhaiter un joyeux Noël 🎄🎁
    
    Cliquez sur le lien pour découvrir mes vœux pour cette Noël 🎅🎄🎉. N'hésitez pas à y ajouter votre nom en bas et à le partager sur vos réseaux sociaux préférés! 🎁✨

      🌟🎉🎄 Click the link to discover my wishes for this Christmas. Feel free to add your name at the bottom and share it on your favorite social networks! 🎁✨

      🎄🎅🎉 Нажмите на ссылку, чтобы узнать мои пожелания на это Рождество. Не стесняйтесь добавлять своё имя внизу и делиться этим в своих любимых социальных сетях! 🎁✨
      
      🎉🎄🎅 Fyonda iyi lien kugirango urabe ivyiza vyanje nkwipfurije kuri Krismasi iri hafi. Niba wifuza kwipfuriza abandi bagenzi bawe, shiramwo amazina yanyu musi hama murungike kuri rumwe mumbuga mukoresha! 🎁✨
      `;

    const elementRefNomComplet = useRef(null)

    const hundleSubmit = (e) => {
        e.preventDefault()
        GetshowFormulaire(false)
        setTimeout(() => {
            GetshowNavigate(true)
        }, 100);
    }

    return (
        <div className='bg-blue-200  select-none flex justify-center  py-5 w-full min-h-screen'>
            <div className={`flex flex-col relative overflow-hidden  bg-white rounded-2xl p-2  ${mobile ? 'w-[95%]' : 'w-[40em]'} h-max`}>
                <div className=' flex justify-between  px-2 w-[100%]'>
                    <div className='fontFamill'>BTR</div>
                    <div className='w-full  h-max overflow-hidden'>
                        <div className='AnimeEmojie'>
                            💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀
                        </div>
                    </div>
                    <div className='fontFamill'>BTR</div>
                </div>
                <div className=' absolute pointer-events-none overflow-hidden writing-vertical bottom-10 w-[35px] flex justify-center items-center p-1   h-[86%]'>
                    <div className={`AnimeEmojieVertleft`}>
                        💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀
                    </div>
                </div>

                <div className='flex-col items-center flex justify-center p-4 '>
                    <div className={`flex justify-between items-center w-full`}>
                        <div className={` ${mobile2 ? 'text-[1.3em]' : mobile1} ${mobile1 ? ' text-[3em]' : ' text-[4em]'} ${mobile3 ? 'text-2xl' : mobile2}`}>
                            🎁
                        </div>
                        <div className={` flex ${mobile3 ? 'flex-col items-center' : ''} ${mobile4 ? 'text-[8.99999999px]' : mobile2} font-serif shadow rounded-xl ${mobile2 ? 'px-[2px] py-[1px] text-[10px]' : 'px-4 py-2'} ${mobile1 ? ' text-xl' : ' text-3xl'}  border border-green-600 max-w-max`}>
                            <div>💗</div>
                            <div className={`Tritre ${mobile4 ? 'text-[14px]' : ''} `}>Joyeux Noël</div>
                            <div>🥀</div>
                        </div>
                        <div className={` ${mobile2 ? 'text-[1.3em]' : mobile1} ${mobile1 ? ' text-[3em]' : ' text-[4em]'} ${mobile3 ? 'text-2xl' : mobile2}`}>
                            🎁
                        </div>
                    </div>


                    <div className={`frigrane ${mobile4 ? 'text-[8.99999999px]' : mobile2} flex justify-center ${mobile2 ? 'text-[10px]' : mobile1} items-center font-serif  ${mobile1 ? 'text-[20px]' : 'text-[30px]'}  w-full h-full`}>
                        Burundi en Temps Réel
                    </div>
                    <div className='w-[95%] flex justify-center items-center mt-1 '>
                        <div className={`p-2 ${mobile4 ? 'text-[9px]' : null}  border font-thin border-green-600  shadow rounded-2xl w-[95%] ${mobile2 ? 'text-[13px] text-center' : 'text-[17px]'}`}>
                            <span className='font-bold'>{nom}</span>  tient à vous souhaiter un <span className='font-bold'>joyeux Noël 🎄🎁</span>, rempli de bonheur, de joie, d'amour et de souvenirs. Nous tenons également à informer vos amies que <span className='font-bold'>BTR</span> 🚀 vous propose une gamme de services de développement, comprenant des applications <span className='font-semibold'>mobiles 📱</span>, des applications de <span className='font-semibold'>bureau 💻</span>, des applications <span className='font-semibold'>web 🌐</span>, la création de <span className='font-semibold'>sites web 🌐</span> et des <span className='font-semibold'>services d'hébergement 🛠️</span>.<br /><br />
                            Nous sommes fiers de proposer gratuitement nos services de développement aux écoles de l'État. Pour les écoles privées, des frais s'appliquent. Contactez-nous pour discuter de vos besoins. Passez de merveilleuses fêtes de Noël! 🎉🎄🎅
                        </div>
                    </div>

                    <div class={`w-full  flex ${mobile5 ? 'flex-col items-start' : 'items-center'} px-5   mt-10`}>
                        <div className='flex justify-end items-center w-full '>
                            <div onClick={(e) => { GetshowFormulaire(!showFormulaire); e.stopPropagation() }} className={`bg-blue-700  text-white ml-5 mt-2 flex justify-center items-center rounded-full cursor-pointer ${mobile2 ? 'w-7 h-7 self-end md:self-auto' : 'w-10 h-10'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-share-fill ${mobile2 ? 'w-4 h-4' : 'w-6 h-6'}`} viewBox="0 0 16 16">
                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                                </svg>
                            </div>
                        </div>
                    </div>



                    <div className={`p-3 z-50   bg-white shadow-2xl   absolute rounded-2xl  bottom-10 flex justify-start flex-wrap  border border-fuchsia-700 ${mobile2 ? ' w-[99%] right-0-0' : ' w-[15em] right-10'} transition-all duration-500 ${showNavigate ? 'opacity-100 pointer-events-auto h-[15em]' : 'opacity-0 pointer-events-none h-[0em]'} `}>
                        <div onClick={() => { GetshowNavigate(false); GetshowFormulaire(false) }} className='absolute right-1 bg-red-600 p-1 rounded-full text-white cursor-pointer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                        </div>
                        <FacebookShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} quote={title}><FacebookIcon size={40} round /></FacebookShareButton>
                        <TwitterShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><TwitterIcon size={40} round /></TwitterShareButton>
                        <LinkedinShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><LinkedinIcon size={40} round /></LinkedinShareButton>
                        <WhatsappShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><WhatsappIcon size={40} round /></WhatsappShareButton>
                        <EmailShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><EmailIcon size={40} round /></EmailShareButton>
                        <FacebookMessengerShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><FacebookMessengerIcon size={32} round /></FacebookMessengerShareButton>
                        <TelegramShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><TelegramIcon size={40} round /></TelegramShareButton>
                        <InstapaperShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><InstapaperIcon size={40} round /></InstapaperShareButton>
                        <RedditShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><RedditIcon size={40} round /></RedditShareButton>
                        <PinterestShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><PinterestIcon size={40} round /></PinterestShareButton>
                        <TumblrShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><TumblrIcon size={40} round /></TumblrShareButton>
                        <VKShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><VKIcon size={40} round /></VKShareButton>
                        <OKShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><OKIcon size={40} round /></OKShareButton>
                        <PocketShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><PocketIcon size={40} round /></PocketShareButton>
                        <LineShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><LineIcon size={40} round /></LineShareButton>
                        <WeiboShareButton style={{ marginLeft: 5, marginRight: 5 }} url={shareUrl} title={title}><WeiboIcon size={40} round /></WeiboShareButton>
                    </div>


                </div>
                <div className='right-0 absolute pointer-events-none  overflow-hidden writing-vertical  w-[40px] flex justify-center items-center p-1 bottom-10   h-[86%] '>
                    <div className='AnimeEmojieVertRigt'>
                        💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀
                    </div>
                </div>
                <div className='  flex justify-between  px-2 w-[100%]'>
                    <div className='fontFamill'>BTR</div>
                    <div className='w-full  h-max overflow-hidden'>
                        <div className='AnimeEmojieBottom'>
                            💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀💕💗💖❤️💐🥀
                        </div>
                    </div>
                    <div className='fontFamill'>BTR</div>
                </div>
                <div className={` absolute w-full h-full top-0 left-0 bg-[#0000006b] flex justify-center transition-all duration-200 ${showFormulaire ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>

                    <div className={`bg-white shadow-2xl w-[98%] min-h-[15em] rounded-lg overflow-hidden text-right absolute   transition-all delay-100 ease-linear  ${showFormulaire ? 'bottom-3 opacity-100 pointer-events-auto' : '-bottom-[50%] opacity-0 pointer-events-none'}`}>
                        <div className={`relative  w-full h-full p-2 sm:p-4`} >
                            <div className='w-full flex justify-center items-center p-1 ms:p-2 flex-col'>
                                <div className=' text-xl font-bold'>
                                    🎄 <span className='Tritre'>Joyeux Noël !</span> 🎄
                                </div>
                                <div className={`mt-3 text-center text-gray-500 ${mobile2 ? 'text-[15px]' : ''}`}>
                                    🎁✨  Cette année, pour partager la joie de la saison,
                                    complète ton nom en indiquant ce que tu aimerais recevoir.
                                    Cela aidera tes amis à connaître tes souhaits et à rendre cette période encore plus spéciale. 🎁✨
                                </div>
                            </div>
                            <div className={`pb-3 ${mobile3 ? 'w-[100%] ' : 'w-[60%] '} `}>
                                <form onSubmit={hundleSubmit} className='flex justify-start flex-col' >
                                    <div className='flex items-start flex-col'>
                                        <label className='max-sm:text-base'>
                                            Votre nom complet
                                        </label>
                                        <input
                                            className='border mt-3 border-fuchsia-700 px-4 py-2 w-full rounded-md outline-none focus:border-2'
                                            onInput={(e) => setActNomVisit(e.target.value)}
                                            ref={elementRefNomComplet}
                                            placeholder="Votre nom complet..."
                                        />
                                    </div>
                                    <div className='flex justify-end mt-1'>
                                        <div onClick={(e) => { e.stopPropagation(); GetshowFormulaire(false) }} className='bg-red-500 px-2 py-1 mx-2 rounded-md text-white cursor-pointer'>Annuler</div>
                                        <input className='bg-fuchsia-500 px-2 py-1 mx-2 rounded-md text-white cursor-pointer' type='submit' value='Partager' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoeAnimation
