import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import NoeAnimation from './NoeAnimation';

function RedirectComponent() {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (window.location.hostname === 'festival.burundientempsreel.com') {
      window.location.href = 'https://www.burundientempsreel.com/';
      //navigate('https://www.burundientempsreel.com/', { replace: true });
    } else {
      //window.location.href = 'https://www.burundientempsreel.com/';
      navigate('/acc', { replace: true });
    }
  }, [navigate]);

  return null; // Since this is a redirect component, no need for actual UI rendering
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectComponent />} />
        <Route path="/:encodedString" element={<NoeAnimation />} />
        <Route path="/acc" element={<NoeAnimation />} />

      </Routes>
    </Router>
  );
}

export default App;
